import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid, Typography, Link as HrefLink } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import dayjs from "dayjs";
import { OrderDetailSummaryCard } from '../components/Ordering/OrderDetailSummaryCard';
import { getOrderableProductsApi } from "../features/commerce/commerceThunkApi";
import { setCurrentOrderData } from "../features/commerce/commerceSlice";
import OrderSummaryTable from '../components/Ordering/OrderSummaryTable';
import { getOrderByIdApi } from "../utils/OrderService/OrderApi";
import { AlertMessage, Spinner } from '../components';
import MessageBanner from "../components/MessageBanner/MessageBanner";
import {
    mapProductNames
} from "../utils/HelperFunctions/OrderAndReservationHelper";
import { loadOrderDetailSummaryComponent } from "../features/contentful/contentfulThunkApi";
import { PAYMENT_OPTIONS } from "../constants";
import { listPaymentMethodsApi } from "../utils/PaymentsService/PaymentMethodApis";
import { getErrorMessage } from "../utils/getErrorMessage";
import { taxExemptionContent, INLINE_VERSION } from "../components/Taxes/TaxExemptionContent";
import {getProducts} from "../utils/CatalogService/CatalogAPI";
import { getOrderableProducts } from "../utils/Helper";

const BannerStyle= {
    padding: "5px",
    color: "#0379B2"
}

const OrderDetailsPage = () => {
    const isPaymentsEnabled = process.env.IS_PAYMENTS_ENABLED === 'true';
    const alertRef = useRef(null);
    const userData = useSelector((store) => store?.commerce?.userData);
    const dispatch = useDispatch();
    const isCatalogServiceEnabled = process.env.IS_CATALOG_SERVICE_ENABLED === 'true';
    const orderableProducts = getOrderableProducts();
    const orderDetails = useSelector((store) => store?.commerce?.currentOrderData);
    const { orderDetailSummaryComponent: orderDetailSummaryContent } = useSelector((store) => store?.contentful);
    const { userId } = userData;
    const { id:orderId } = useParams();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState();
    const [isTaxPending, setIsTaxPending] = useState(true);
    const [paymentInfo, setPaymentInfo] = useState(null);
    const isTaxPhase3Enabled = process.env.IS_TAX_PHASE_3_ENABLED === 'true';

    useEffect( () => {
        try{
            setProcessing(true);
            dispatch(getOrderByIdApi({userId,orderId})).unwrap()
            .then(async (res) => {
                dispatch(setCurrentOrderData({currentOrderData:res}))
                if (isPaymentsEnabled) {
                    let paymentType = res?.paymentType;
                    if (paymentType === PAYMENT_OPTIONS.PAY_NOW) {
                        await listPaymentMethodsApi({}).then(async (response) => {
                            if (response.status === 200) {
                                const allPaymentMethods = await fetchAllPaymentMethods();
                                const paymentMethodUsed = allPaymentMethods.find(pm => pm.paymentMethodId === res?.paymentMethodId);
                                setPaymentInfo(paymentMethodUsed);
                            }
                        }).catch((err) => {
                            let errMsg = getErrorMessage(err?.response?.data?.errorCode);
                            setError(errMsg);
                            alertRef.current?.openAlert(err);
                            setProcessing(false);
                        })
                    }
                }
                const isTaxComputed = res?.orderPriceTotals?.isTaxComputed;
                const taxExemptionSelected = res?.orderPriceTotals?.taxOverrideDetails?.taxExemptionOverrideReason === 'CUSTOMER_SELF_SELECTION';
                setIsTaxPending((!isTaxComputed && !taxExemptionSelected) ?? true);
                setProcessing(false)
            });
        }catch(err){
            setError(err?.message);
            alertRef.current?.openAlert(err);
            setProcessing(false);
        }
    }, [dispatch, orderId, userId]);


    const fetchAllPaymentMethods = async (nextToken = null, paymentMethods = []) => {
        try {
            const response = await listPaymentMethodsApi({ nextToken });
            if (response.status === 200) {
                const newPaymentMethods = paymentMethods.concat(response?.data?.paymentMethodsList || []);
                if (response.data.nextToken) {
                    return fetchAllPaymentMethods(response.data.nextToken, newPaymentMethods);
                }
                return newPaymentMethods;
            }
        } catch (error) {
            let errMsg = getErrorMessage(error?.response?.data?.errorCode);
            setError(errMsg);
            alertRef.current?.openAlert(error);
            setProcessing(false);
        }
    };

    useEffect(() => {
        try{
            setProcessing(true);
            let promise;

            if (isCatalogServiceEnabled) {
                promise = dispatch(getProducts({ locale: 'US' })).unwrap();
            } else {
                promise = dispatch(getOrderableProductsApi({ catalogVersion: 'modernaProductCatalog' })).unwrap();
            }

            promise.then(() => {
                setProcessing(false);
            })
        }catch(err){
            setError(err?.message);
            alertRef.current?.openAlert(err);
            setProcessing(false);
        }
    }, [dispatch]);

    useEffect(() => {
        if (orderDetailSummaryContent && Object.keys(orderDetailSummaryContent).length === 0)
          dispatch(loadOrderDetailSummaryComponent());
      }, [orderDetailSummaryContent, dispatch]);

    const productMap = mapProductNames(orderableProducts);

    const formattedOrderDetail = useMemo(()=>{
        return {
            subtotalListPrice:orderDetails?.orderPriceTotals?.subtotalListPrice?.displayValue,
            subtotalContractPrice:orderDetails?.orderPriceTotals?.subtotalContractPrice?.displayValue,
            totalTax:orderDetails?.orderPriceTotals?.totalTax?.displayValue,
            totalContractPrice:orderDetails?.orderPriceTotals?.totalContractPrice?.displayValue,
            purchaseOrderNumber:orderDetails?.purchaseOrderNumber,
            shipToAddress:{
                ...orderDetails?.shipToAddress
            },
            billToAddress:{
                ...orderDetails?.billToAddress
            },
            items:orderDetails?.orderLineItems?.map((item)=>{
                return {
                    //todo what to do if didn't find matched name for productId?
                    productName: productMap[item?.productId],
                    productId: item?.productId,
                    listPrice:item?.unitListPrice?.displayValue,
                    contractPrice:item?.unitContractPrice?.displayValue,
                    doses: item?.quantity
                }
            })
        }
    },[orderDetails,productMap]);

    const summaryData=useMemo(()=>{
        const orderPriceDetails = orderDetails?.orderPriceTotals;
        return {
            paymentType: orderDetails?.paymentType,
            promptPayEligible:orderPriceDetails?.promptPayEligible,
            promptPayTerms: orderPriceDetails?.promptPayTerms,
            totalContractPriceWithPromptPay: orderPriceDetails?.totalContractPriceWithPromptPay?.displayValue,
            totalDoses: orderDetails?.totalQuantity || orderDetails?.orderLineItems?.reduce((sum, item) => sum + item.quantity, 0),
            totalPrice: orderPriceDetails?.totalContractPrice?.displayValue,
            listPrice: orderPriceDetails?.subtotalListPrice?.displayValue,
            contractPrice:orderPriceDetails?.subtotalContractPrice?.displayValue,
            savings:orderPriceDetails?.discountTotal?.displayValue,
            taxes:orderPriceDetails?.totalTax?.displayValue,
            isTaxPending: isTaxPending,
            paymentInfo: paymentInfo
        }
    },[orderDetails, isTaxPending, paymentInfo]);

    const getHoldMessage = (orderHoldReasons) => {
        const customerNotConfirmedWithShipper = orderHoldReasons.includes("CUSTOMER_NOT_CONFIRMED_WITH_SHIPPER");
        const shipperNotReady = orderHoldReasons.includes("SHIPPER_NOT_TAKING_ORDERS");
        const productNotReady = orderHoldReasons.includes("PRODUCT_NOT_READY_FOR_SHIPPING");
    
        if (customerNotConfirmedWithShipper && shipperNotReady) {
            return {
                headerText: orderDetailSummaryContent?.customerAndShipperNotReadyHeader,
                messageText: orderDetailSummaryContent?.customerAndShipperNotReadyMessage
            };
        } 
        if (customerNotConfirmedWithShipper) {
            return {
                headerText: orderDetailSummaryContent?.customerNotConfirmedHeader,
                messageText: orderDetailSummaryContent?.customerNotConfirmedMessage
            };
        } 
        if (shipperNotReady) {
            return {
                headerText: orderDetailSummaryContent?.shipperNotReadyHeader,
                messageText: orderDetailSummaryContent?.shipperNotReadyMessage
            };
        }
        if(productNotReady) {
            return {
                headerText: orderDetailSummaryContent?.productNotReadyHeader,
                messageText: orderDetailSummaryContent?.productNotReadyMessage
            };
        }
        return {
            headerText: orderDetailSummaryContent?.defaultOnHoldHeader,
            messageText: orderDetailSummaryContent?.defaultOnHoldMessage
        };
    }

    const getBannerInfo=useCallback((orderStatus)=>{
        switch(orderStatus){
            case "ON_HOLD": 
            case "INTERNAL_HOLD":
                if (orderDetails?.orderHoldReasons.length > 0) {
                    const holdMessage = getHoldMessage(orderDetails.orderHoldReasons);
                    return {
                        type: "warning",
                        headerText: holdMessage?.headerText,
                        bannerContentComponent: 
                        (
                            <Typography>
                                {holdMessage?.messageText}
                            </Typography>
                        ),
                        dataTestId:"OrderDetailStatusBanner"
                    };
                } else {
                    return {
                        type: "warning",
                        headerText: "Your order is on hold",
                        bannerContentComponent: 
                        (
                            <Typography>
                                This order has been placed on hold. Please 
                                <Link to="/contact-us" style={BannerStyle}>Contact us</Link>
                                 with any questions.
                            </Typography>
                        ),
                        dataTestId:"OrderDetailStatusBanner"
                    };
                }
            case "CANCELLED":
                return {
                    type: "canceled",
                    headerText: "This order has been cancelled",
                    bannerContentComponent: 
                    (
                        <Typography>
                            This order has been cancelled. Please 
                            <Link to="/contact-us" style={BannerStyle}>Contact us</Link> 
                            for more information or for help replacing your order.
                        </Typography>
                    ),
                    dataTestId:"OrderDetailStatusBanner"
                };
            case "COMPLETED":
            case "FULFILLED":
                return {
                    type: "shipped",
                    headerText: orderDetails?.orderShippedDate ?
                        `This order shipped on ${dayjs(orderDetails?.orderShippedDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}` :
                        "This order has shipped",
                    bannerContentComponent: 
                    (
                        orderDetails?.shipment?.shipper === "UPS" &&
                            <>
                            <Typography>
                                Carrier: UPS
                            </Typography>
                            {orderDetails?.shipment?.trackingId &&
                                <Typography>
                                    Tracking number:
                                    <HrefLink target="_blank" rel='#trackingNumber' href={`https://www.ups.com/track?trackingNumber=${orderDetails?.shipment?.trackingId}`} style={BannerStyle}>{orderDetails?.shipment?.trackingId}</HrefLink>
                                </Typography>
                            }
                            </>
                    ),
                    dataTestId:"OrderDetailStatusBanner"
                };
            case "CREATED":
            case "CONFIRMED":
            case "PENDING":
            default:
                return {
                    type: "info",
                    headerText: "This order is processing",
                    bannerContentComponent: 
                    (
                        <Typography>
                            For questions about your order please
                            <Link to="/contact-us" style={BannerStyle}>Contact us</Link>
                        </Typography>
                    ),
                    dataTestId:"OrderDetailStatusBanner"
                };
        }
    },[orderDetails?.orderId, orderDetailSummaryContent, orderDetails?.orderShippedDate]);

    const bannerInfo= useMemo(()=>{
        return getBannerInfo(orderDetails?.orderStatus);
    },[getBannerInfo, orderDetails?.orderStatus]);
    
    return (
        <>
            <AlertMessage
            variant={"filled"}
            type={"error"}
            message={error}
            sx={{ top: 120 }}
            ref={alertRef}
            />
            <Spinner processing={processing} />
            <Grid
                data-testid="orderDetailContainer"
                container
                spacing={{ xs: 1, lg: 4 }}
                padding={{ xs: 2, md: 3, lg: 12 }}
            >
                {orderDetails?.orderStatus && 
                    <Grid item xs={12}>
                        <MessageBanner
                        type={bannerInfo?.type}
                        headerText={bannerInfo?.headerText}
                        bannerContentComponent={bannerInfo?.bannerContentComponent}
                        dataTestId={bannerInfo?.dataTestId}
                        />
                    </Grid>
                }
                { isTaxPhase3Enabled && 
                    <Grid item xs={12}>
                        <MessageBanner
                            type='info'
                            headerText="Tax-exempt"
                            bannerContentComponent={taxExemptionContent(INLINE_VERSION)}
                            dataTestId="TaxInstructionSection"
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Typography
                        data-testid="orderDetailsPageHeader"
                        sx={{
                            fontSize: "30px",
                            fontWeight: 500,
                            lineHeight: "36px",
                            fontFamily: "Aeonik Regular",
                        }}
                    >
                    {`Order #${orderDetails?.orderNumber}`}
                    </Typography>
                </Grid>
                <Grid data-testid={"order-summary-table"} item sm={12} lg2={8} >
                    <OrderSummaryTable orderDetail={formattedOrderDetail} isTaxPending={isTaxPending} sx={{'#boxContainer':  "33px" }}/>
                </Grid>
                <Grid data-testid={"order-summary-card"} item sm={12} lg2={4} overflow="unset">
                    <OrderDetailSummaryCard summaryData={summaryData} />
                </Grid>
            </Grid>
        </>
        )
}

export default OrderDetailsPage;
